import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import Grid from 'src/components/Grid'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import TextLockup from 'src/components/TextLockup'
import Image from 'src/components/Image'
import { util, colors } from 'src/styles'

const Wrapper = styled.div`
	min-height: 100vh;
	background-color: ${colors.mainColor};
	display: flex;
	flex-direction: column;
	align-items: center;
`

const EventWrapper = styled.div`
	flex-grow: 1;
	${ util.responsiveStyles('padding-top', 75, 75, 75, 50) }
	${ util.responsiveStyles('padding-bottom', 175, 175, 175, 50) }

`

const EventInfo = styled.div`

`

const propTypes = {
	data: PropTypes.object.isRequired,
}

const StyledFooter = styled(Footer)`
	width: 100%;
`

const StyledText = styled(TextLockup)`
	${ util.responsiveStyles('padding-top', 50, 50, 50, 25) };
`

class EventTemplate extends React.Component {
	state = {

	}

	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.title.includes('PLACEHOLDER'))[0].node
		const event = this.props.data.allContentfulEvent.edges[0].node
		const seo = event.seo
		return (
			<Wrapper>
				<SEO
					title={event.eventTitle}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				<Header
					hasAtf={false}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          pageType={"event"}
          location={event.slug}
				/>

				<EventWrapper>
					<Grid
						small="1 [12] 1"
						medium="2 [10] 2"
						large="2 [10] 2"
					>
						<h1>{event.eventTitle}</h1>
						<p>{event.eventDateTime}</p>

						<Image 
							{...event.image}
						/>
						<StyledText
							text={event.eventDescription}
						/>

					</Grid>

				</EventWrapper>
 
				<StyledFooter {...site} />
			</Wrapper>
		)
	}
}

EventTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {nin: "PLACEHOLDER Site Settings"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulEvent(sort: {fields: eventDateTime, order: DESC}, filter: {id: {eq: $id}, internalName: {nin: "PLACEHOLDER"}}) {
	    edges {
	      node {
	        eventTitle
	        eventDateTime(formatString:"MMM D YYYY, h:mm a")
	        eventDescription {
	          json
	        }
	        image {
	        	...Image
	        }
	        internalName
	        slug
	        id
	        seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
	      }
	    }
	  }
	}
`

export default EventTemplate
